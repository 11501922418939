// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { tipeAktiva } from "../../api";
import { toast } from 'react-toastify'
import Avatar from "../../components/avatar"
import {Check,X} from "react-feather"

export const getTipeAktiva = createAsyncThunk(
  "tipe_aktiva/getAllData",
  async (token) => {
    const resp = await tipeAktiva.getAll(token);
    return resp.data;
  }
);

export const createTipeAktiva = createAsyncThunk('tipe_aktiva/create', async ({token,data}) => {
    const resp = await tipeAktiva.create(token,data);
    return resp;
})
  
export const updateTipeAktiva = createAsyncThunk('tipe_aktiva/update', async ({token,data,id}) => {
    const resp = await tipeAktiva.update(token,data,id);
    return resp;
})
  
export const deleteTipeAktiva = createAsyncThunk('tipe_aktiva/delete', async ({token,id}) => {
    const resp = await tipeAktiva.remove(token,id);
    return resp.data;
})

export const tipeAktivaSlice = createSlice({
  name: "tipe_aktiva",
  initialState: {
    dataTipeAktiva: [],
    error: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTipeAktiva.rejected, (state, action) => {
        state.error = "Error Get Data !";
      })
      .addCase(getTipeAktiva.fulfilled, (state, action) => {
        state.dataTipeAktiva = action.payload.data;
      })
      .addCase(createTipeAktiva.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateTipeAktiva.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(createTipeAktiva.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateTipeAktiva.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
  },
});

export default tipeAktivaSlice.reducer;
