// ** Redux Imports
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { report } from '../../api'

export const getReportPembelian = createAsyncThunk('report/pembelian', async ({token,data}) => {
  const resp = await report.reportPembelian(token,data);
  return resp.data;
})

export const reportSlice = createSlice({
  name: 'report',
  initialState: {
    reportPembelian: [],
    columnReportPembelian: [],
    error: ""
  },
  extraReducers: builder => {
    builder
      .addCase(getReportPembelian.rejected, (state,action) => {
        state.error = "Error Get Data !";
      })
      .addCase(getReportPembelian.fulfilled, (state, action) => {
        state.reportPembelian = action.payload.data;
        
        state.columnReportPembelian = action.payload.data ? Object.keys(action.payload.data[0]).filter((value) => value !== 'KeyIndex') : ''
      })
  }
})

export default reportSlice.reducer
