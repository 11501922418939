// ** Redux Imports
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { auth } from '../api' 

export const authLogin = createAsyncThunk('auth/login', async (data) => {
  const {email,password} = data;
  const resp = await auth.login({email,password});
  return resp.data;
})

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    token: '',
    isLoading: false,
    error: "",
    menuData: []
  },
  reducers: {
    handleLogout: state => {
      state.userData = {}
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('token')
      localStorage.removeItem('menuData')
    }
  },
  extraReducers: builder => {
    builder
      .addCase(authLogin.rejected, (state,action) => {
        state.isLoading = false;
        state.error = "Silahkan cek email dan password anda !";
      })
      .addCase(authLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(authLogin.fulfilled, (state, action) => {
        const payload = action.payload.data;
        const menuData = [
          {
            id: 'dashboard',
            name: 'Dashboard',
            icon: 'fa-solid fa-house',
            navLink: '/dashboard'
          },
        ]

        if(payload?.menu != null){
          payload?.menu.map(item => {
            menuData.push(item)
          })
        }

        state.menuData = menuData
        state.userData = payload
        state.isLoading = false
        localStorage.setItem('userData', JSON.stringify(payload))
        localStorage.setItem('token', JSON.stringify(payload?.token))
        localStorage.setItem('menuData', JSON.stringify(menuData))
      })
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
