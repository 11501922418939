// ** Redux Imports
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { valas } from '../../api'
import { toast } from 'react-toastify'
import Avatar from "../../components/avatar"
import {Check,X} from "react-feather"

export const getValas = createAsyncThunk('valas/getAllData', async (token) => {
  const resp = await valas.getAll(token);
  return resp.data;
})

export const createValas = createAsyncThunk('valas/create', async ({token,data}) => {
  const resp = await valas.create(token,data);
  return resp;
})

export const updateValas = createAsyncThunk('valas/update', async ({token,data,id}) => {
  const resp = await valas.update(token,data,id);
  return resp;
})

export const deleteValas = createAsyncThunk('valas/delete', async ({token,id}) => {
  const resp = await valas.remove(token,id);
  return resp;
})

export const valasSlice = createSlice({
  name: 'valas',
  initialState: {
    dataValas: [],
    selectValas: [],
    error: ""
  },
  extraReducers: builder => {
    builder
        .addCase(getValas.fulfilled, (state, action) => {
            state.dataValas = action.payload.data
            const optValas = action.payload.data.map((item) => {
              return {
                value: item.KODEVLS,
                data: item,
                label: `${item.KODEVLS}`
              }
            })
            state.selectValas = optValas;
        })
        .addCase(getValas.rejected, (state,action) => {
            state.error = "Error Get Data !";
            toast.error(
                <>
                <div className='toastify-header'>
                    <div className='title-wrapper'>
                    <Avatar size='sm' color='danger' icon={<X size={12} />} />
                    <h6 className='toast-title'>Failed</h6>
                    </div>
                </div>
                <div className='toastify-body'>
                    Error Get Data !
                </div>
                </>,
                { icon: false, hideProgressBar: true }
            )
        })
      .addCase(createValas.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateValas.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(createValas.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateValas.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
  }
})

export default valasSlice.reducer
