// ** Redux Imports
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { aktiva, browse } from '../../api'
import { toast } from 'react-toastify'
import Avatar from "../../components/avatar"
import {Check,X} from "react-feather"

export const getAktiva = createAsyncThunk('aktiva/getAllData', async (token) => {
  const resp = await aktiva.getAll(token);
  return resp.data;
})

export const browseBiaya = createAsyncThunk('aktiva/browseBiaya', async (token) => {
  const resp = await browse.browseBiaya(token);
  return resp.data;
})

export const browseAkumulasi = createAsyncThunk('aktiva/browseAkumulasi', async (token) => {
  const resp = await browse.browseAkumulasi(token);
  return resp.data;
})

export const browsePAktiva = createAsyncThunk('aktiva/browsePerkiraanAktiva', async (token) => {
  const resp = await browse.browsePerkiraanAktiva(token);
  return resp.data;
})

export const browseArea = createAsyncThunk('aktiva/browseArea', async (token) => {
  const resp = await browse.browseArea(token);
  return resp.data;
})

export const createAktiva = createAsyncThunk('aktiva/create', async ({token,data}) => {
  const resp = await aktiva.create(token,data);
  return resp;
})

export const updateAktiva = createAsyncThunk('aktiva/update', async ({token,data,id}) => {
  const resp = await aktiva.update(token,data,id);
  return resp;
})

export const deleteAktiva = createAsyncThunk('aktiva/delete', async ({token,id}) => {
  const resp = await aktiva.remove(token,id);
  return resp.data;
})


export const aktivaSlice = createSlice({
  name: 'aktiva',
  initialState: {
    dataAktiva: [],
    dataBiaya: [],
    dataAkumulasi: [],
    dataPAktiva: [],
    dataArea: [],
    selectArea: [],
    error: ""
  },
  extraReducers: builder => {
    builder
        .addCase(getAktiva.fulfilled, (state, action) => {
          state.dataAktiva = action.payload.data
        })
        .addCase(browseBiaya.fulfilled, (state, action) => {
          state.dataBiaya = action.payload.data
        })
        .addCase(browseAkumulasi.fulfilled, (state, action) => {
          state.dataAkumulasi = action.payload.data
        })
        .addCase(browsePAktiva.fulfilled, (state, action) => {
          state.dataPAktiva = action.payload.data
        })
        .addCase(browseArea.fulfilled, (state, action) => {
          state.dataArea = action.payload.data
          const selectArea = action.payload.data.map((item) => {
            return {
              value: item.KodeArea,
              label: `${item.KodeArea} | ${item.NamaArea}`
            }
          })
          state.selectArea = selectArea
        })
        .addCase(getAktiva.rejected, (state,action) => {
            state.error = "Error Get Data !";
            toast.error(
                <>
                <div className='toastify-header'>
                    <div className='title-wrapper'>
                    <Avatar size='sm' color='danger' icon={<X size={12} />} />
                    <h6 className='toast-title'>Failed</h6>
                    </div>
                </div>
                <div className='toastify-body'>
                    Error Get Data !
                </div>
                </>,
                { icon: false, hideProgressBar: true }
            )
        })
      .addCase(createAktiva.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateAktiva.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(createAktiva.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateAktiva.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
  }
})

export default aktivaSlice.reducer
