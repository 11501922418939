// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { merk } from "../../api";
import { toast } from "react-toastify";
import Avatar from "../../components/avatar";
import { Check, X } from "react-feather";

export const getMerk = createAsyncThunk("merk/getAllData", async (token) => {
  const resp = await merk.getAll(token);
  return resp.data;
});

export const createMerk = createAsyncThunk(
  "merk/create",
  async ({ token, data }) => {
    const resp = await merk.create(token, data);
    return resp;
  }
);

export const updateMerk = createAsyncThunk(
  "merk/update",
  async ({ token, data, id }) => {
    const resp = await merk.update(token, data, id);
    return resp;
  }
);

export const deleteMerk = createAsyncThunk(
  "merk/delete",
  async ({ token, data, id }) => {
    const resp = await merk.remove(token, data, id);
    return resp;
  }
);

export const merkSlice = createSlice({
  name: "merk",
  initialState: {
    dataMerk: [],
    selectMerk: [],
    columnMerk: [],
    error: "",
    message: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMerk.rejected, (state, action) => {
        state.error = "Error Get Data !";
      })
      .addCase(getMerk.fulfilled, (state, action) => {
        const selectCabang = action.payload.data.map((item) => {
          return {
            value: item.KodeMerk,
            label: `${item.KodeMerk} | ${item.NamaMerk}`
          }
        })
        state.columnMerk = Object.keys(action.payload.data[0]);
        state.dataMerk = action.payload.data;
        state.selectMerk = selectCabang;
      })
      .addCase(createMerk.fulfilled, (state, action) => {
        state.message = action.payload.data.message;
        toast.success(
          <>
            <div className="toastify-header">
              <div className="title-wrapper">
                <Avatar size="sm" color="success" icon={<Check size={12} />} />
                <h6 className="toast-title">Success</h6>
              </div>
            </div>
            <div className="toastify-body">
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        );
      })
      .addCase(updateMerk.fulfilled, (state, action) => {
        state.message = action.payload.data.message;
        toast.success(
          <>
            <div className="toastify-header">
              <div className="title-wrapper">
                <Avatar size="sm" color="success" icon={<Check size={12} />} />
                <h6 className="toast-title">Success</h6>
              </div>
            </div>
            <div className="toastify-body">
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        );
      })
      .addCase(createMerk.rejected, (state, action) => {
        toast.error(
          <>
            <div className="toastify-header">
              <div className="title-wrapper">
                <Avatar size="sm" color="danger" icon={<X size={12} />} />
                <h6 className="toast-title">Failed</h6>
              </div>
            </div>
            <div className="toastify-body">Failed Create Data</div>
          </>,
          { icon: false, hideProgressBar: true }
        );
      })
      .addCase(updateMerk.rejected, (state, action) => {
        toast.error(
          <>
            <div className="toastify-header">
              <div className="title-wrapper">
                <Avatar size="sm" color="danger" icon={<X size={12} />} />
                <h6 className="toast-title">Failed</h6>
              </div>
            </div>
            <div className="toastify-body">Failed Create Data</div>
          </>,
          { icon: false, hideProgressBar: true }
        );
      })
      .addCase(deleteMerk.fulfilled, (state, action) => {
        state.message = action.payload.data.message;
        toast.success(
          <>
            <div className="toastify-header">
              <div className="title-wrapper">
                <Avatar size="sm" color="success" icon={<Check size={12} />} />
                <h6 className="toast-title">Success</h6>
              </div>
            </div>
            <div className="toastify-body">
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        );
      })
      .addCase(deleteMerk.rejected, (state, action) => {
        toast.error(
          <>
            <div className="toastify-header">
              <div className="title-wrapper">
                <Avatar size="sm" color="danger" icon={<X size={12} />} />
                <h6 className="toast-title">Failed</h6>
              </div>
            </div>
            <div className="toastify-body">Failed Create Data</div>
          </>,
          { icon: false, hideProgressBar: true }
        );
      });
  },
});

export default merkSlice.reducer;
