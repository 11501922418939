// ** Redux Imports
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { group } from '../../api'
import { toast } from 'react-toastify'
import Avatar from "../../components/avatar"
import {Check,X} from "react-feather"

export const getGroup = createAsyncThunk('group/getAll', async (token) => {
  const resp = await group.getAll(token);
  return resp.data;
})

export const createGroup = createAsyncThunk('group/create', async ({token,data}) => {
  const resp = await group.create(token,data);
  return resp;
})

export const updateGroup = createAsyncThunk('group/update', async ({token,data,id}) => {
  const resp = await group.update(token,data,id);
  return resp;
})

export const deleteGroup = createAsyncThunk('group/delete', async ({token,id}) => {
  const resp = await group.remove(token,id);
  return resp;
})

export const groupSlice = createSlice({
  name: 'group',
  initialState: {
    dataGroup: [],
    selectGroup: [],
    error: ""
  },
  extraReducers: builder => {
    builder
      .addCase(getGroup.rejected, (state,action) => {
        state.error = "Error Get Data !";
      })
      .addCase(getGroup.fulfilled, (state, action) => {
        const selectGroup = action.payload.data.map((item) => {
            return {
                value: item.KodeGrp,
                label: `${item.KodeGrp} | ${item.NamaGrp}`
            }
        })
        state.selectGroup = selectGroup
        state.dataGroup = action.payload.data;
      })
      .addCase(createGroup.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateGroup.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(createGroup.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateGroup.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
  }
})

export default groupSlice.reducer
