import axios from 'axios';

export const getAll = async (token) => {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/menu`,{headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const getMenuDetail = async (token,data) => {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/menu/formEdit_lookup_menuUser`,data,{headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const createMenu = async (token,data) => {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/menu/dataAdd`,data,{headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const editMenu = async (token,data) => {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/menu/dataEdit`,data,{headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const deleteMenu = async (token,data) => {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/menu/dataDelete`,data,{headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const editMenuUser = async (token,data) => {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/menu/dataEdit_menuUser`,data,{headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export default {getAll,createMenu,editMenu,deleteMenu,getMenuDetail,editMenuUser}