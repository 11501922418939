// ** Redux Imports
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { cabang } from '../../api'
import { toast } from 'react-toastify'
import Avatar from "../../components/avatar"
import {Check,X} from "react-feather"

export const getCabang = createAsyncThunk('cabang/getAll', async (token) => {
  const resp = await cabang.getAll(token);
  return resp.data;
})

export const createCabang = createAsyncThunk('cabang/create', async ({token,data}) => {
  const resp = await cabang.create(token,data);
  return resp;
})

export const updateCabang = createAsyncThunk('cabang/update', async ({token,data,id}) => {
  const resp = await cabang.update(token,data,id);
  return resp;
})

export const deleteCabang = createAsyncThunk('cabang/delete', async ({token,id}) => {
  const resp = await cabang.remove(token,id);
  return resp;
})

export const cabangSlice = createSlice({
  name: 'cabang',
  initialState: {
    selectCabang: [],
    dataCabang: [],
    error: ""
  },
  extraReducers: builder => {
    builder
      .addCase(getCabang.rejected, (state,action) => {
        state.error = "Error Get Data !";
      })
      .addCase(getCabang.fulfilled, (state, action) => {
        const selectCabang = action.payload.data.map((item) => {
          return {
            value: item.KodeCabang,
            label: `${item.KodeCabang} | ${item.NamaCabang}`
          }
        })
        state.dataCabang = action.payload.data;
        state.selectCabang = selectCabang;
      })
      .addCase(createCabang.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateCabang.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(createCabang.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateCabang.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
  }
})

export default cabangSlice.reducer
