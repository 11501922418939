// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { penerimaan } from "../../api";
import { toast } from "react-toastify";
import Avatar from "../../components/avatar";
import { Check, X } from "react-feather";

export const getKasBank = createAsyncThunk("penerimaan/browse", async ({token,data}) => {
  const resp = await penerimaan.browse(token,data);
  return resp.data;
});

export const getByNoBukti = createAsyncThunk("penerimaan/getByNoBukti", async ({token,id}) => {
  const resp = await penerimaan.getByNoBukti(token,id);
  return resp.data;
}) 

export const getLawan = createAsyncThunk("penerimaan/getDataLawan", async (token) => {
  const resp = await penerimaan.browseLawan(token);
  return resp.data;
}) 

export const createKasBank = createAsyncThunk("penerimaan/createKasBank", async ({token,data}) => {
  const resp = await penerimaan.create(token,data);
  return resp;
}) 

export const updateKasBank = createAsyncThunk("penerimaan/updateKasBank", async ({token,data,id}) => {
  const resp = await penerimaan.update(token,data,id);
  return resp;
}) 

export const deleteKasBank = createAsyncThunk("penerimaan/deleteKasBank", async ({token,data,id}) => {
  const resp = await penerimaan.deletes(token,data,id);
  return resp;
}) 

export const penerimaanSlice = createSlice({
  name: "penerimaan",
  initialState: {
    dataPenerimaan: [],
    browseLawan: [],
    kasBank: [],
    error: "",
    message: "",
    nobukti: "",
  },
  reducers: {
    resetData(state,action) {
      state.dataPenerimaan = []
      state.kasBank = []
    } 
  },
  extraReducers: (builder) => {
    builder
      .addCase(getKasBank.rejected, (state, action) => {
        state.error = "Error Get Data !";
      })
      .addCase(getKasBank.fulfilled, (state, action) => {
        state.kasBank = action.payload.data;
      })
      .addCase(getLawan.rejected, (state, action) => {
        state.error = "Error Get Data !";
      })
      .addCase(getLawan.fulfilled, (state, action) => {
        state.browseLawan = action.payload.data;
      })
      .addCase(createKasBank.fulfilled, (state, action) => {
        state.message = action.payload.data.message;
        state.nobukti = action.payload.data.data[0].NoBukti;
        toast.success(
          <>
            <div className="toastify-header">
              <div className="title-wrapper">
                <Avatar size="sm" color="success" icon={<Check size={12} />} />
                <h6 className="toast-title">Success</h6>
              </div>
            </div>
            <div className="toastify-body">
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        );
      })
      .addCase(createKasBank.rejected, (state, action) => {
        toast.error(
          <>
            <div className="toastify-header">
              <div className="title-wrapper">
                <Avatar size="sm" color="danger" icon={<X size={12} />} />
                <h6 className="toast-title">Failed</h6>
              </div>
            </div>
            <div className="toastify-body">Failed Create Data</div>
          </>,
          { icon: false, hideProgressBar: true }
        );
      })
      .addCase(updateKasBank.fulfilled, (state, action) => {
        state.message = action.payload.data.message;
        toast.success(
          <>
            <div className="toastify-header">
              <div className="title-wrapper">
                <Avatar size="sm" color="success" icon={<Check size={12} />} />
                <h6 className="toast-title">Success</h6>
              </div>
            </div>
            <div className="toastify-body">
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        );
      })
      .addCase(updateKasBank.rejected, (state, action) => {
        toast.error(
          <>
            <div className="toastify-header">
              <div className="title-wrapper">
                <Avatar size="sm" color="danger" icon={<X size={12} />} />
                <h6 className="toast-title">Failed</h6>
              </div>
            </div>
            <div className="toastify-body">Failed Update Data</div>
          </>,
          { icon: false, hideProgressBar: true }
        );
      })
      .addCase(deleteKasBank.fulfilled, (state, action) => {
        state.message = action.payload.data.message;
        toast.success(
          <>
            <div className="toastify-header">
              <div className="title-wrapper">
                <Avatar size="sm" color="success" icon={<Check size={12} />} />
                <h6 className="toast-title">Success</h6>
              </div>
            </div>
            <div className="toastify-body">
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        );
      })
      .addCase(deleteKasBank.rejected, (state, action) => {
        toast.error(
          <>
            <div className="toastify-header">
              <div className="title-wrapper">
                <Avatar size="sm" color="danger" icon={<X size={12} />} />
                <h6 className="toast-title">Failed</h6>
              </div>
            </div>
            <div className="toastify-body">Failed Delete Data</div>
          </>,
          { icon: false, hideProgressBar: true }
        );
      })
      .addCase(getByNoBukti.rejected, (state, action) => {
        state.error = "Error Get Data !";
      })
      .addCase(getByNoBukti.fulfilled, (state, action) => {
        state.dataPenerimaan = action.payload.data;
      })
  },
});

export const { resetData } = penerimaanSlice.actions

export default penerimaanSlice.reducer;
