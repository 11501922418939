// ** Redux Imports
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { ekspedisi } from '../../api'
import { toast } from 'react-toastify'
import Avatar from "../../components/avatar"
import {Check,X} from "react-feather"

export const getEkspedisi = createAsyncThunk('ekspedisi/getAll', async (token) => {
  const resp = await ekspedisi.getAll(token);
  return resp.data;
})

export const createEkspedisi = createAsyncThunk('ekspedisi/create', async ({token,data}) => {
  const resp = await ekspedisi.create(token,data);
  return resp;
})

export const updateEkspedisi = createAsyncThunk('ekspedisi/update', async ({token,data,id}) => {
  const resp = await ekspedisi.update(token,data,id);
  return resp;
})

export const deleteEkspedisi = createAsyncThunk('ekspedisi/delete', async ({token,id}) => {
  const resp = await ekspedisi.remove(token,id);
  return resp;
})

export const ekspedisiSlice = createSlice({
  name: 'ekspedisi',
  initialState: {
    dataEkspedisi: [],
    error: ""
  },
  extraReducers: builder => {
    builder
      .addCase(getEkspedisi.rejected, (state,action) => {
        state.error = "Error Get Data !";
      })
      .addCase(getEkspedisi.fulfilled, (state, action) => {
        state.dataEkspedisi = action.payload.data;
      })
      .addCase(createEkspedisi.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateEkspedisi.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(createEkspedisi.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateEkspedisi.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
  }
})

export default ekspedisiSlice.reducer
