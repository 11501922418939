// ** Redux Imports
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { supplier } from '../../api'
import { toast } from 'react-toastify'
import Avatar from "../../components/avatar"
import {Check,X} from "react-feather"

export const getSupplier = createAsyncThunk('supplier/getAllData', async (token) => {
  const resp = await supplier.getAll(token);
  return resp.data;
})

export const getAccHutang = createAsyncThunk('supplier/getAccHutang', async (token) => {
  const resp = await supplier.getAccHutang(token);
  return resp.data;
})

export const createSupplier = createAsyncThunk('supplier/create', async ({token,data}) => {
  const resp = await supplier.create(token,data);
  return resp;
})

export const updateSupplier = createAsyncThunk('supplier/update', async ({token,data,id}) => {
  const resp = await supplier.update(token,data,id);
  return resp;
})

export const deleteSupplier = createAsyncThunk('supplier/delete', async ({token,id}) => {
  const resp = await supplier.remove(token,id);
  return resp;
})

export const supplierSlice = createSlice({
  name: 'supplier',
  initialState: {
    dataSupplier: [],
    accHutang: [],
    error: ""
  },
  extraReducers: builder => {
    builder
        .addCase(getSupplier.fulfilled, (state, action) => {
            state.dataSupplier = action.payload.data
        })
        .addCase(getSupplier.rejected, (state,action) => {
            state.error = "Error Get Data !";
            toast.error(
                <>
                <div className='toastify-header'>
                    <div className='title-wrapper'>
                    <Avatar size='sm' color='danger' icon={<X size={12} />} />
                    <h6 className='toast-title'>Failed</h6>
                    </div>
                </div>
                <div className='toastify-body'>
                    Error Get Data !
                </div>
                </>,
                { icon: false, hideProgressBar: true }
            )
        })
        .addCase(getAccHutang.fulfilled, (state, action) => {
          state.accHutang = action.payload.data
        })
        .addCase(getAccHutang.rejected, (state,action) => {
            state.error = "Error Get Data !";
            toast.error(
                <>
                <div className='toastify-header'>
                    <div className='title-wrapper'>
                    <Avatar size='sm' color='danger' icon={<X size={12} />} />
                    <h6 className='toast-title'>Failed</h6>
                    </div>
                </div>
                <div className='toastify-body'>
                    Error Get Acc Hutang !
                </div>
                </>,
                { icon: false, hideProgressBar: true }
            )
        })
        .addCase(createSupplier.fulfilled, (state,action) => {
          state.message = action.payload.data.message
          toast.success(
            <>
              <div className='toastify-header'>
                <div className='title-wrapper'>
                  <Avatar size='sm' color='success' icon={<Check size={12} />} />
                  <h6 className='toast-title'>Success</h6>
                </div>
              </div>
              <div className='toastify-body'>
                {action?.payload?.data?.message}
              </div>
            </>,
            { icon: false, hideProgressBar: true }
          )
        })
        .addCase(updateSupplier.fulfilled, (state,action) => {
          state.message = action.payload.data.message
          toast.success(
            <>
              <div className='toastify-header'>
                <div className='title-wrapper'>
                  <Avatar size='sm' color='success' icon={<Check size={12} />} />
                  <h6 className='toast-title'>Success</h6>
                </div>
              </div>
              <div className='toastify-body'>
                {action?.payload?.data?.message}
              </div>
            </>,
            { icon: false, hideProgressBar: true }
          )
        })
        .addCase(createSupplier.rejected, (state,action) => {
          toast.error(
            <>
              <div className='toastify-header'>
                <div className='title-wrapper'>
                  <Avatar size='sm' color='danger' icon={<X size={12} />} />
                  <h6 className='toast-title'>Failed</h6>
                </div>
              </div>
              <div className='toastify-body'>
                {action?.payload?.data?.message}
              </div>
            </>,
            { icon: false, hideProgressBar: true }
          )
        })
        .addCase(updateSupplier.rejected, (state,action) => {
          toast.error(
            <>
              <div className='toastify-header'>
                <div className='title-wrapper'>
                  <Avatar size='sm' color='danger' icon={<X size={12} />} />
                  <h6 className='toast-title'>Failed</h6>
                </div>
              </div>
              <div className='toastify-body'>
                {action?.payload?.data?.message}
              </div>
            </>,
            { icon: false, hideProgressBar: true }
          )
        })
  }
})

export default supplierSlice.reducer
