// ** Redux Imports
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { jabatan } from '../../api'
import { toast } from 'react-toastify'
import Avatar from "../../components/avatar"
import {Check,X} from "react-feather"

export const getJabatan = createAsyncThunk('jabatan/getAll', async (token) => {
  const resp = await jabatan.getAll(token);
  return resp.data;
})

export const createJabatan = createAsyncThunk('jabatan/create', async ({token,data}) => {
  const resp = await jabatan.create(token,data);
  return resp;
})

export const updateJabatan = createAsyncThunk('jabatan/update', async ({token,data,id}) => {
  const resp = await jabatan.update(token,data,id);
  return resp;
})

export const deleteJabatan = createAsyncThunk('jabatan/delete', async ({token,id}) => {
  const resp = await jabatan.remove(token,id);
  return resp;
})

export const jabatanSlice = createSlice({
  name: 'jabatan',
  initialState: {
    dataJabatan: [],
    error: ""
  },
  extraReducers: builder => {
    builder
      .addCase(getJabatan.rejected, (state,action) => {
        state.error = "Error Get Data !";
      })
      .addCase(getJabatan.fulfilled, (state, action) => {
        state.dataJabatan = action.payload.data;
      })
      .addCase(createJabatan.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateJabatan.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(createJabatan.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateJabatan.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
  }
})

export default jabatanSlice.reducer
