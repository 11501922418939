// ** Redux Imports
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { perkiraan, cabang } from '../../api' 
import { toast } from 'react-toastify'
import Avatar from "../../components/avatar"
import {Check,X} from "react-feather"

export const getPerkiraan = createAsyncThunk('perkiraan/getAllData', async ({token,params}) => {
  const resp = await perkiraan.getAll(token,params);
  return resp.data;
})

export const getCabang = createAsyncThunk('perkiraan/getCabang', async (token) => {
  const resp = await cabang.getAll(token);
  return resp.data;
})

export const createPerkiraan = createAsyncThunk('perkiraan/create', async ({token,data}) => {
  const resp = await perkiraan.create(token,data);
  return resp;
})

export const updatePerkiraan = createAsyncThunk('perkiraan/update', async ({token,data,id}) => {
  const resp = await perkiraan.update(token,data,id);
  return resp;
})

export const deletePerkiraan = createAsyncThunk('perkiraan/delete', async ({token,data,id}) => {
  const resp = await perkiraan.remove(token,data,id);
  return resp;
})

export const getByKelompok = createAsyncThunk('perkiraan/getByKelompok', async ({token,tipe,kelompok,keyword}) => {
  const resp = await perkiraan.getByKelompok( token,tipe,kelompok,keyword);
  return resp.data;
})

export const perkiraanSlice = createSlice({
  name: 'perkiraan',
  initialState: {
    dataPerkiraan: [],
    dataByKelompok: [],
    columnPerkiraan: [],
    cabang: [],
    kelompok: [],
    error: "",
    message: "",
    isLoading: false,
  },
  extraReducers: builder => {
    builder
      .addCase(getPerkiraan.rejected, (state,action) => {
        state.error = "Error Get Data !";
      })
      .addCase(getPerkiraan.fulfilled, (state, action) => {
        const uniqueIds = new Set();

        const unique = action.payload.data.filter(element => {
          const isDuplicate = uniqueIds.has(element.idKelompok);

          uniqueIds.add(element.idKelompok);

          if (!isDuplicate) {
            return true;
          }

          return false;
        }).map((item) => {
          return {
            value: item.idKelompok,
            label: `${item.idKelompok} | ${item.nmKelompok}`
          }
        });

        const sortIdKelompok = unique.sort((a,b) => {
          return a.value - b.value
        })

        state.columnPerkiraan = Object.keys(action.payload.data[0])
        state.dataPerkiraan = action.payload.data
        state.kelompok = sortIdKelompok
      })
      .addCase(getCabang.rejected, (state,action) => {
        state.error = "Error Get Data !";
      })
      .addCase(getCabang.fulfilled, (state, action) => {
        const dataCabang = action.payload.data.map((item) => {
          return {
            value: item.KodeCabang,
            label: `${item.KodeCabang} | ${item.NamaCabang}`
          }
        })
        state.cabang = dataCabang
      })
      .addCase(getByKelompok.fulfilled, (state,action) => {
        state.dataByKelompok = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getByKelompok.pending, (state,action) => {
        state.isLoading = true;
      })
      .addCase(getByKelompok.rejected, (state,action) => {
        state.isLoading = false;
      })
      .addCase(createPerkiraan.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updatePerkiraan.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(createPerkiraan.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              Failed Create Data
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updatePerkiraan.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              Failed Create Data
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(deletePerkiraan.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(deletePerkiraan.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              Failed Create Data
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
  }
})

export default perkiraanSlice.reducer
