import axios from 'axios';

export const getAll = async (token) => {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/perkiraan`, {headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const create = async (token,data) => {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/perkiraan/dataAdd`,data, {headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const update = async (token,data,id) => {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/perkiraan/dataEdit/${id}`,data,{headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const remove = async (token,data,id) => {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/perkiraan/dataDelete/${id}`,data,{headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const getByKelompok = async (token,tipe,kelompok,keyword) => {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/perkiraan/getByKelompok`,{headers: { 'Authorization': `Bearer ${token}` }, params: {tipe:tipe, kelompok: kelompok, keyword: keyword}})
    return resp;
}

export default {getAll,create,update,remove,getByKelompok}