// ** Redux Imports
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { model } from '../../api'
import { toast } from 'react-toastify'
import Avatar from "../../components/avatar"
import {Check,X} from "react-feather"

export const getModel = createAsyncThunk('model/getAllData', async (token) => {
  const resp = await model.getAll(token);
  return resp.data;
})

export const createModel = createAsyncThunk('model/create', async ({token,data}) => {
  const resp = await model.create(token,data);
  return resp;
})

export const updateModel = createAsyncThunk('model/update', async ({token,data,id}) => {
  const resp = await model.update(token,data,id);
  return resp;
})

export const deleteModel = createAsyncThunk('model/delete', async ({token,id}) => {
  const resp = await model.remove(token,id);
  return resp;
})

export const modelSlice = createSlice({
  name: 'model',
  initialState: {
    dataModel: [],
    selectModel: [], 
    error: ""
  },
  extraReducers: builder => {
    builder
        .addCase(getModel.fulfilled, (state, action) => {
          const selectModel = action.payload.data.map((item) => {
              return {
                  value: item.KodeModel,
                  label: `${item.KodeModel} | ${item.NamaModel}`
              }
          })
          state.dataModel = action.payload.data
          state.selectModel = selectModel
        })
        .addCase(getModel.rejected, (state,action) => {
            state.error = "Error Get Data !";
            toast.error(
                <>
                <div className='toastify-header'>
                    <div className='title-wrapper'>
                    <Avatar size='sm' color='danger' icon={<X size={12} />} />
                    <h6 className='toast-title'>Failed</h6>
                    </div>
                </div>
                <div className='toastify-body'>
                    Error Get Data !
                </div>
                </>,
                { icon: false, hideProgressBar: true }
            )
        })
      .addCase(createModel.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateModel.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(createModel.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateModel.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
  }
})

export default modelSlice.reducer
