import axios from "axios";

export const getAll = async (token) => {
  const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/merk`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return resp;
};

export const create = async (token, data) => {
  const resp = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/merk/dataAdd`,
    data,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return resp;
};

export const update = async (token, data, id) => {
  const resp = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/merk/dataEdit/${id}`,
    data,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return resp;
};

export const remove = async (token, data, id) => {
  const resp = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/merk/dataDelete/${id}`,
    data,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return resp;
};

export default { getAll, create, update, remove };
