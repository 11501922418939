// ** Redux Imports
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { browse } from '../../api'
import { toast } from 'react-toastify'
import Avatar from "../../components/avatar"
import {Check,X} from "react-feather"

export const getArea = createAsyncThunk('area/getArea', async (token) => {
  const resp = await browse.browseArea(token);
  return resp.data;
})

export const areaSlice = createSlice({
  name: 'area',
  initialState: {
    dataArea: [],
    error: ""
  },
  extraReducers: builder => {
    builder
        .addCase(getArea.fulfilled, (state, action) => {
          state.dataArea = action.payload.data
        })
  }
})

export default areaSlice.reducer
