import axios from 'axios';

export const getAll = async (token) => {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/users`,{headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const getPeriode = async (token) => {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/getPeriode`,{headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const setPeriode = async(token,data) => {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/users/setPeriode`, data ,{headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export default {getAll,getPeriode,setPeriode}