// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { tipePerkiraan } from "../../api";

export const getTipePerkiraan = createAsyncThunk(
  "tipe_perkiraan/getAllData",
  async (token) => {
    const resp = await tipePerkiraan.getAll(token);
    return resp.data;
  }
);

export const tipePerkiraanSlice = createSlice({
  name: "tipe_perkiraan",
  initialState: {
    dataTipePerkiraan: [],
    columnTipePerkiraan: [],
    error: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTipePerkiraan.rejected, (state, action) => {
        state.error = "Error Get Data !";
      })
      .addCase(getTipePerkiraan.fulfilled, (state, action) => {
        state.columnTipePerkiraan = Object.keys(action.payload.data[0]);
        state.dataTipePerkiraan = action.payload.data;
      });
  },
});

export default tipePerkiraanSlice.reducer;
