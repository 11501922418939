// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { users } from '../../src/api';
import { toast } from 'react-toastify'
import Avatar from "../components/avatar"
import {Check,X} from "react-feather"

// ** Axios Imports
import axios from 'axios'
import { DateTime } from 'luxon';

export const getBookmarks = createAsyncThunk('layout/getBookmarks', async () => {
  const response = await axios.get('/api/bookmarks/data')
  return {
    data: response.data.suggestions,
    bookmarks: response.data.bookmarks
  }
})

export const updateBookmarked = createAsyncThunk('layout/updateBookmarked', async id => {
  await axios.post('/api/bookmarks/update', { id })
  return id
})

export const getPeriode =  createAsyncThunk('navbar/getPeriode', async token => {
  const resp = await users.getPeriode(token);
  return resp.data;
})

export const setPeriode =  createAsyncThunk('navbar/updatePeriode', async ({token,data}) => {
  const resp = await users.setPeriode(token,data);
  return resp.data;
})

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    query: '',
    bookmarks: [],
    suggestions: [],
    periode: {},
    datePeriode: '',
    modalPeriode: false,
  },
  reducers: {
    handleSearchQuery: (state, action) => {
      state.query = action.payload
    },
    openModalPeriode: (state,action) => {
      state.modalPeriode = !state.modalPeriode;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getPeriode.fulfilled, (state,action) => {
        const conv = `${action.payload.data.Bulan}/${action.payload.data.Tahun}`
        state.periode.formated = conv;
        state.datePeriode = DateTime.now().set({month: action.payload.data.Bulan, year: action.payload.data.Tahun}).toJSDate();
        state.periode.bulan = action.payload.data.Bulan;
        state.periode.tahun = action.payload.data.Tahun;
      })
      .addCase(setPeriode.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              Success update periode
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(setPeriode.rejected, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='error' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              Network Error!
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(getBookmarks.fulfilled, (state, action) => {
        state.suggestions = action.payload.data
        state.bookmarks = action.payload.bookmarks
      })
      .addCase(updateBookmarked.fulfilled, (state, action) => {
        let objectToUpdate

        // ** find & update object
        state.suggestions.find(item => {
          if (item.id === action.payload) {
            item.isBookmarked = !item.isBookmarked
            objectToUpdate = item
          }
        })

        // ** Get index to add or remove bookmark from array
        const bookmarkIndex = state.bookmarks.findIndex(x => x.id === action.payload)

        if (bookmarkIndex === -1) {
          state.bookmarks.push(objectToUpdate)
        } else {
          state.bookmarks.splice(bookmarkIndex, 1)
        }
      })
  }
})

export const { handleSearchQuery,openModalPeriode } = layoutSlice.actions

export default layoutSlice.reducer
