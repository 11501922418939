import axios from "axios";

export const getAll = async (token) => {
  const resp = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/tipeperkiraan`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return resp;
};

export default { getAll };
