import axios from 'axios';

export const getAll = async (token,params) => {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/salesman`, {params, headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const create = async (token,data) => {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/salesman/dataAdd`,data, {headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const update = async (token,data,id) => {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/salesman/dataEdit/${id}`,data,{headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const remove = async (token,data,id) => {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/salesman/dataDelete/${id}`,data,{headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export default {getAll,create,update,remove}