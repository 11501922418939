import axios from 'axios';

export const browse = async (token,data) => {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/browse/penerimaan?tipe=${data.tipe}&date=${data.date}`, {headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const browseLawan = async (token) => {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/browse/lawan`, {headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const getByNoBukti = async (token,id) => {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/penerimaan/formEdit/${id}`, {headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const create = async (token,data) => {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/penerimaan/dataAdd`, data, {headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const update = async (token,data,id) => {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/penerimaan/dataEdit/${id}`, data, {headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const deletes = async (token,data,id) => {
    const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/penerimaan/dataDelete/${id}`, data, {headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export default {browse,browseLawan,getByNoBukti,create,update,deletes}