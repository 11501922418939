// ** Redux Imports
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { groupcustomer } from '../../api'
import { toast } from 'react-toastify'
import Avatar from "../../components/avatar"
import {Check,X} from "react-feather"

export const getGroupCustomer = createAsyncThunk('groupcustomer/getAll', async (token) => {
  const resp = await groupcustomer.getAll(token);
  return resp.data;
})

export const createGroupCustomer = createAsyncThunk('groupcustomer/create', async ({token,data}) => {
  const resp = await groupcustomer.create(token,data);
  return resp;
})

export const updateGroupCustomer = createAsyncThunk('groupcustomer/update', async ({token,data,id}) => {
  const resp = await groupcustomer.update(token,data,id);
  return resp;
})

export const deleteGroupCustomer = createAsyncThunk('groupcustomer/delete', async ({token,id}) => {
  const resp = await groupcustomer.remove(token,id);
  return resp;
})

export const groupCustomerSlice = createSlice({
  name: 'groupcustomer',
  initialState: {
    dataGroupCustomer: [],
    error: ""
  },
  extraReducers: builder => {
    builder
      .addCase(getGroupCustomer.rejected, (state,action) => {
        state.error = "Error Get Data !";
      })
      .addCase(getGroupCustomer.fulfilled, (state, action) => {
        state.dataGroupCustomer = action.payload.data;
      })
      .addCase(createGroupCustomer.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateGroupCustomer.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(createGroupCustomer.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateGroupCustomer.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
  }
})

export default groupCustomerSlice.reducer
