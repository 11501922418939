// ** Redux Imports
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { users } from '../../api'

export const getUsers = createAsyncThunk('users/getAllData', async (token) => {
  const resp = await users.getAll(token);
  return resp.data;
})

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    userSelect: [],
    users: [],
    userDetail: {},
    error: ""
  },
  extraReducers: builder => {
    builder
      .addCase(getUsers.rejected, (state,action) => {
        state.error = "Error Get Data !";
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        const user = action.payload.data.map((item) => {
            return {value: item.id, label:item.username}
        })
        state.userSelect = user;
        state.users = action.payload.data;
      })
  }
})

export default usersSlice.reducer
