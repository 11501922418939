// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";

import customer from './pages/customer'
import perkiraan from './pages/perkiraan'
import tipePerkiraan from './pages/tipeperkiraan'
import menu from './pages/menu'
import users from './pages/users'
import gudang from './pages/gudang'
import merk from "./pages/merk";

import report from './pages/report'
import penerimaan from './pages/penerimaan'
import salesman from './pages/salesman'
import supplier from './pages/supplier'
import valas from './pages/valas'
import model from './pages/model'
import aktiva from './pages/aktiva'
import cabang from './pages/cabang'
import labarugi from './pages/labarugi'
import ekspedisi from './pages/ekspedisi'
import jabatan from './pages/jabatan'
import groupcustomer from './pages/groupcustomer'
import tipeAktiva from './pages/tipeaktiva'
import group from './pages/group'
import barang from './pages/barang'
import area from './pages/area'

const rootReducer = {
  auth,
  navbar,
  layout,

  perkiraan,
  tipePerkiraan,
  menu,
  customer,
  users,
  gudang,

  report,
  merk,
  penerimaan,
  salesman,
  supplier,
  valas,
  model,
  aktiva,
  cabang,
  labarugi,
  ekspedisi,
  jabatan,
  groupcustomer,
  tipeAktiva,
  barang,
  group,
  area,
};

export default rootReducer;
