// ** Redux Imports
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { salesman } from '../../api'
import { toast } from 'react-toastify'
import Avatar from "../../components/avatar"
import {Check,X} from "react-feather"

export const getSalesman = createAsyncThunk('salesman/getAllData', async ({token,params}) => {
  const resp = await salesman.getAll(token,params);
  return resp.data;
})

export const createSalesman = createAsyncThunk('salesman/create', async ({token,data}) => {
  const resp = await salesman.create(token,data);
  return resp;
})

export const updateSalesman = createAsyncThunk('salesman/update', async ({token,data,id}) => {
  const resp = await salesman.update(token,data,id);
  return resp;
})

export const deleteSalesman = createAsyncThunk('salesman/delete', async ({token,id}) => {
  const resp = await salesman.remove(token,id);
  return resp;
})

export const salesmanSlice = createSlice({
  name: 'salesman',
  initialState: {
    dataSalesman: [],
    error: ""
  },
  extraReducers: builder => {
    builder
        .addCase(getSalesman.fulfilled, (state, action) => {
            state.dataSalesman = action.payload.data
        })
        .addCase(getSalesman.rejected, (state,action) => {
            state.error = "Error Get Data !";
            toast.error(
                <>
                <div className='toastify-header'>
                    <div className='title-wrapper'>
                    <Avatar size='sm' color='danger' icon={<X size={12} />} />
                    <h6 className='toast-title'>Failed</h6>
                    </div>
                </div>
                <div className='toastify-body'>
                    Error Get Data !
                </div>
                </>,
                { icon: false, hideProgressBar: true }
            )
        })
      .addCase(createSalesman.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateSalesman.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(createSalesman.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateSalesman.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
  }
})

export default salesmanSlice.reducer
