// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { menu } from "../../api";
import { toast } from "react-toastify";
import Avatar from "../../components/avatar";
import { Check, X } from "react-feather";

export const getMenu = createAsyncThunk("menu/getAllData", async (token) => {
  const resp = await menu.getAll(token);
  return resp.data;
});

export const addMenu = createAsyncThunk(
  "menu/addMenu",
  async ({ token, data }) => {
    const resp = await menu.createMenu(token, data);
    return resp.data;
  }
);

export const editMenu = createAsyncThunk(
  "menu/editMenu",
  async ({ token, data }) => {
    const resp = await menu.editMenu(token, data);
    return resp.data;
  }
);

export const deleteMenu = createAsyncThunk(
  "menu/deleteMenu",
  async ({ token, data }) => {
    const resp = await menu.deleteMenu(token, data);
    return resp.data;
  }
);

export const menuSlice = createSlice({
  name: "menu",
  initialState: {
    dataMenu: [],
    columnMenu: [],
    menuSelect: [],
    error: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMenu.rejected, (state, action) => {
        state.error = "Error Get Data !";
      })
      .addCase(getMenu.fulfilled, (state, action) => {
        const arrMenu = [
          {value: '0', label: '0 - Utama'}
        ]
        const menuSelect = action.payload.data.map((item,key) => {
          arrMenu.push({value: item.MenuID, label: `${item.MenuID} - ${item.Name.replace(/&nbsp;/g,' ')}`});
        });
        state.columnMenu = Object.keys(action.payload.data[0])
        state.dataMenu = action.payload.data
        state.menuSelect = arrMenu
      })
      .addCase(addMenu.fulfilled, (state, action) => {
        state.message = action.payload.data.message;
        toast.success(
          <>
            <div className="toastify-header">
              <div className="title-wrapper">
                <Avatar size="sm" color="success" icon={<Check size={12} />} />
                <h6 className="toast-title">Success</h6>
              </div>
            </div>
            <div className="toastify-body">
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        );
      })
      .addCase(editMenu.fulfilled, (state, action) => {
        state.message = action.payload.data.message;
        toast.success(
          <>
            <div className="toastify-header">
              <div className="title-wrapper">
                <Avatar size="sm" color="success" icon={<Check size={12} />} />
                <h6 className="toast-title">Success</h6>
              </div>
            </div>
            <div className="toastify-body">
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        );
      })
      .addCase(deleteMenu.fulfilled, (state, action) => {
        state.message = action.payload.data.message;
        toast.success(
          <>
            <div className="toastify-header">
              <div className="title-wrapper">
                <Avatar size="sm" color="success" icon={<Check size={12} />} />
                <h6 className="toast-title">Success</h6>
              </div>
            </div>
            <div className="toastify-body">
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        );
      });
  },
});

export default menuSlice.reducer;
