// ** Redux Imports
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { labarugi } from '../../api'
import { toast } from 'react-toastify'
import Avatar from "../../components/avatar"
import {Check,X} from "react-feather"

export const getLabaRugi = createAsyncThunk('labarugi/getAll', async (token) => {
  const resp = await labarugi.getAll(token);
  return resp.data;
})

export const createLabaRugi = createAsyncThunk('labarugi/create', async ({token,data}) => {
  const resp = await labarugi.create(token,data);
  return resp;
})

export const updateLabaRugi = createAsyncThunk('labarugi/update', async ({token,data,id}) => {
  const resp = await labarugi.update(token,data,id);
  return resp;
})

export const deleteLabaRugi = createAsyncThunk('labarugi/delete', async ({token,id}) => {
  const resp = await labarugi.remove(token,id);
  return resp;
})

export const labaRugiSlice = createSlice({
  name: 'labarugi',
  initialState: {
    dataLabaRugi: [],
    error: ""
  },
  extraReducers: builder => {
    builder
      .addCase(getLabaRugi.rejected, (state,action) => {
        state.error = "Error Get Data !";
      })
      .addCase(getLabaRugi.fulfilled, (state, action) => {
        state.dataLabaRugi = action.payload.data;
      })
      .addCase(createLabaRugi.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateLabaRugi.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(createLabaRugi.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateLabaRugi.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
  }
})

export default labaRugiSlice.reducer
