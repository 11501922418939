import axios from 'axios';

export const browseBiaya = async (token) => {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/browse/pbiaya`,{headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const browseAkumulasi = async (token) => {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/browse/pakumulasi`,{headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const browsePerkiraanAktiva = async (token) => {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/browse/paktiva`,{headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export const browseArea = async (token) => {
    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/browse/area`,{headers: { 'Authorization': `Bearer ${token}` }})
    return resp;
}

export default {browseBiaya,browseAkumulasi,browsePerkiraanAktiva,browseArea}