// ** Redux Imports
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { customer,gudang } from '../../api'
import { toast } from 'react-toastify'
import Avatar from "../../components/avatar"
import {Check,X} from "react-feather"

export const getCustomer = createAsyncThunk('customer/getAllData', async (token) => {
  const resp = await customer.getAll(token);
  return resp.data;
})

export const getGudang = createAsyncThunk('customer/getGudang', async (token) => {
  const resp = await gudang.getAll(token);
  return resp.data;
})

export const createCustomer = createAsyncThunk('customer/create', async ({token,data}) => {
  const resp = await customer.create(token,data);
  return resp;
})

export const updateCustomer = createAsyncThunk('customer/update', async ({token,data,id}) => {
  const resp = await customer.update(token,data,id);
  return resp;
})

export const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    dataCustomer: [],
    columnCustomer: [],
    gudang: [],
    error: ""
  },
  extraReducers: builder => {
    builder
      .addCase(getCustomer.rejected, (state,action) => {
        state.error = "Error Get Data !";
      })
      .addCase(getCustomer.fulfilled, (state, action) => {
        state.columnCustomer = Object.keys(action.payload.data[0])
        state.dataCustomer = action.payload.data
      })
      .addCase(getGudang.rejected, (state,action) => {
        state.error = "Error Get Data !";
      })
      .addCase(getGudang.fulfilled, (state, action) => {
        const gudang = action.payload.data.map((item) => {
          return {
            value: item.KodeGdg,
            label: `${item.KodeGdg} | ${item.NamaGdg}`
          }
        })
        state.gudang = gudang
      })
      .addCase(createCustomer.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateCustomer.fulfilled, (state,action) => {
        state.message = action.payload.data.message
        toast.success(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>Success</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(createCustomer.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
      .addCase(updateCustomer.rejected, (state,action) => {
        toast.error(
          <>
            <div className='toastify-header'>
              <div className='title-wrapper'>
                <Avatar size='sm' color='danger' icon={<X size={12} />} />
                <h6 className='toast-title'>Failed</h6>
              </div>
            </div>
            <div className='toastify-body'>
              {action?.payload?.data?.message}
            </div>
          </>,
          { icon: false, hideProgressBar: true }
        )
      })
  }
})

export default customerSlice.reducer
